.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.swiper1{
  background-image: url(../src/Components/Images/slide01.jpg);
}
.swiper2{
  background-image: url(../src/Components/Images/slide02.jpg);
}
.swiper3{
  background-image: url(../src/Components/Images/slide03.jpg);
}
.react-multiple-carousel__arrow--right {
  right: calc(0% + 0px);
}
.react-multiple-carousel__arrow--left {
  left: calc(0% + 0px);
}
@media only screen and (max-width: 1660px){
  .slider .slider-container .swiper-slide .container{
    position: relative;
      z-index: 9;
      padding-left: 140px;
  }
  .slider .slider-container .inner-elements .container .social-media {
    width: 20px;
    position: absolute;
    left: 42px;
    top: calc(50% + 60px);
    transform: translateY(-50%);
    z-index: 4;
    text-align: center;
  }
  }
@media only screen and (min-width: 1514px){
.slider .slider-container .swiper-slide .container{
  position: relative;
    z-index: 9;
    padding-left: 180px;
}
.slider .slider-container .inner-elements .container .social-media {
  width: 20px;
  position: absolute;
  left: 72px;
  top: calc(50% + 60px);
  transform: translateY(-50%);
  z-index: 4;
  text-align: center;
}
}@media only screen and (min-width: 1444px){
  .slider .slider-container .swiper-slide .container{
    position: relative;
      z-index: 9;
      padding-left: 180px;
  }
  .slider .slider-container .inner-elements .container .social-media {
    width: 20px;
    position: absolute;
    left: 102px;
    top: calc(50% + 60px);
    transform: translateY(-50%);
    z-index: 4;
    text-align: center;
  }
  }@media only screen and (min-width: 1370px){
    .slider .slider-container .swiper-slide .container{
      position: relative;
        z-index: 9;
        padding-left: 200px;
    }
    .slider .slider-container .inner-elements .container .social-media {
      width: 20px;
      position: absolute;
      left: 142px;
      top: calc(50% + 60px);
      transform: translateY(-50%);
      z-index: 4;
      text-align: center;
    }
    }@media only screen and (min-width: 1290px){
      .slider .slider-container .swiper-slide .container{
        position: relative;
          z-index: 9;
          padding-left: 210px;
      }
      .slider .slider-container .inner-elements .container .social-media {
        width: 20px;
        position: absolute;
        left: 162px;
        top: calc(50% + 60px);
        transform: translateY(-50%);
        z-index: 4;
        text-align: center;
      }
      }
      @media only screen and (min-width: 1260px){
        .slider .slider-container .swiper-slide .container{
          position: relative;
            z-index: 9;
            padding-left: 210px;
        }
        .slider .slider-container .inner-elements .container .social-media {
          width: 20px;
          position: absolute;
          left: 162px;
          top: calc(50% + 60px);
          transform: translateY(-50%);
          z-index: 4;
          text-align: center;
        }
        }
        @media only screen and (min-width: 1210px){
          .slider .slider-container .swiper-slide .container{
            position: relative;
              z-index: 9;
              padding-left: 210px;
          }
          .slider .slider-container .inner-elements .container .social-media {
            width: 20px;
            position: absolute;
            left: 162px;
            top: calc(50% + 60px);
            transform: translateY(-50%);
            z-index: 4;
            text-align: center;
          }
          }
  


        @media only screen and (max-width: 767px), only screen and (max-device-width: 767px){
.slider .slider-container .swiper-slide .container {
  padding-left: 70px;

}}


.gallery-container {
  max-width: 800px;
  margin: 0 auto;
}

.slick-dots li button:before {
  color: #C0AB89;
}

.slick-prev:before, .slick-next:before {
  color: #C0AB89;
}

img {
  max-width: 100%;
}
.dot-list {
  list-style: none;
  padding-left: 0;
}

.dot-list li:before {
  content: "\2022";
  margin-right: 10px;
}
.grid .entry-media img {
  width: 100%;
  height: 200px; /* set the desired height here */
  object-fit: cover; /* this will ensure the image fills the container while preserving its aspect ratio */
}
.slick-prev:before, .slick-next:before{
  font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: rgb(192, 171, 137)!important;
    -webkit-font-smoothing: antialiased;
}
.slick-prev {
  left: -13px!important;
  z-index:999!important;
}
.slick-next {
  right: -12px!important;
  z-index:999!important;

}

.readMore{
  height: 45px;
  line-height: 56px;
  float: left;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  /* padding-bottom: 10px; */
  position: relative;
  border: 2px solid #ebcfa7;
  padding: 0 10px;
  box-shadow: 0 0 20px rgba(235, 207, 167, 0.4);
  transition: 0.25s ease-in-out;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.readMore:hover{
  text-decoration: none;
  background: #9f8054;
  border-color: #9f8054;
  color: #fff;
}


.breadcrumbAr {
  width: 100%;
  padding: 0;
  border-radius: 0;
  background: none;
}
.breadcrumbAr .breadcrumbAr-item {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  padding: 0;
}
.breadcrumbAr .breadcrumbAr-item:first-child:after {
  display: none;
}
.breadcrumbAr .breadcrumbAr-item:after {
  content: "»";
  color: #fff;
  font-weight: 400;
  padding: 0 20px;
}
.swiper-button-next, .swiper-button-prev {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: calc(var(--swiper-navigation-size) / 44* 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px -(var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9f8054!important;
}
.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #9f8054!important;
}